.body {
  font-family: "Lora", serif;
  color: rgb(255, 255, 255);
  background: #7c908d;
  margin: 0;
  height: 100%;
}

.details {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  text-align: right;
  padding-top: 10px;
  padding-right: 10px;
  z-index: 10;
}

.details a {
  color: white;
}

.detailsText {
  color: white;
  text-decoration: none;
  text-align: right;
  margin-right: 20px;
}

.h1 {
  font-weight: 400;
  font-size: large;
  padding-bottom: 20px;
}

.h2 {
  font-weight: 400;
  font-size: medium;
}

.h4 {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  opacity: 70%;
  padding-top: 0%;
  margin-top: 0%;
}

.hero {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 95vh;
  padding-left: 5%;
  padding-right: 5%;
  z-index: 1;
}

.sectionText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  align-items: center;
  text-align: center;
  padding: 5%;
  padding-top: 10%;
}

.backgroundImageMap {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  align-items: center;
  height: 300px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(/public/images/map.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.backgroundImageComp {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  align-items: center;
  height: 300px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(/public/images/compass.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.backgroundImageAstro {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  align-items: center;
  height: 300px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(/public/images/astrolabe.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.backgroundImageSext {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  align-items: center;
  height: 300px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(/public/images/sextant.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ipod {
  width: 50%;
  max-width: 400px;
}

.arrow-img {
  max-width: 30%;
  max-height: 130px;
  position: absolute;
  bottom: 100px;
  right: 400px;
}

.last-section {
  height: 250px;
}

@media (max-width: 600px) {
  .click-wheel-container {
    right: 0 !important;
    left: 0 !important;
    margin: auto;
  }
}

input[type="email"],
button[type="submit"] {
  padding: 4px 7px;
}
